import * as React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  HeadingProps,
  TableContainer,
} from '@chakra-ui/react';

import { formatPrice } from '../utils/format-price';
import Layout from '../components/layout';
import LineItem from '../components/LineItem';
import PickUpChokerListing from '../components/PickUpChokerListing';
import { maxW } from '../assets/config';
import { StoreContext } from '../context/StoreContext';
import JsonLd from '../components/JsonLd';
import Seo from '../components/seo';

const HeadingSummary: React.FC<HeadingProps> = props => (
  <Heading mb={2} fontSize={{ base: 'xl', md: '3xl' }} {...props}>
    {props.children}
  </Heading>
);

export default function CartPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { checkout, loading } = React.useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  return (
    <Layout>
      <Seo
        title="カートページ"
        description="カート内の商品を確認するページです。"
      />
      <JsonLd
        title="カートページ"
        description="カート内の商品を確認するページです。"
      />

      {/* wrap */}
      <Box py={12} maxW={maxW} mx="auto">
        {emptyCart ? (
          <>
            <Heading
              fontSize={{ base: '3xl', xl: '6xl' }}
              fontWeight={400}
              mb={4}
            >
              カートに商品がありません。
            </Heading>
            <Text
              fontWeight={400}
              fontSize={{ base: '2xl', md: '3xl', xl: '4xl' }}
            >
              <Link to="/store">
                <u>こちら</u>
              </Link>
              から商品をぜひご確認ください！
              <br />
              ※一回のご購入5000円（税込）以上につき、送料無料にて承ります。
            </Text>
            <Heading
              fontFamily="Haetten, Sawarabi Gothic, sans-serif"
              letterSpacing={2}
              fontWeight={400}
              fontSize="6xl"
              textAlign="center"
              my={8}
            >
              〜 <u>PICK UP</u>〜
            </Heading>
            <PickUpChokerListing />
          </>
        ) : (
          <Box maxW={maxW} mx="auto">
            <Heading mb={8}>カート内の商品</Heading>
            <TableContainer>
              <Table>
                <Thead display={isMobile ? 'none' : undefined}>
                  <Tr>
                    <Th fontSize={{ base: 'md', md: '2xl' }}>画像</Th>
                    <Th fontSize={{ base: 'md', md: '2xl' }}>商品</Th>
                    <Th fontSize={{ base: 'md', md: '2xl' }}>価格</Th>
                    <Th fontSize={{ base: 'md', md: '2xl' }}>個数</Th>
                    <Th fontSize={{ base: 'md', md: '2xl' }}>合計</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {checkout.lineItems.map(item => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Box my={8}>
              <HeadingSummary>小計</HeadingSummary>
              <Text fontSize={{ base: 'md', md: '2xl' }} mb={4}>
                {formatPrice(
                  checkout.subtotalPriceV2?.currencyCode || 'USD',
                  Number(checkout.subtotalPriceV2?.amount) || 0
                )}
              </Text>
              <HeadingSummary>税</HeadingSummary>
              <Text fontSize={{ base: 'md', md: '2xl' }} mb={4}>
                {formatPrice(
                  checkout.subtotalPriceV2?.currencyCode || 'USD',
                  Number(checkout.subtotalPriceV2?.amount) || 0
                )}
              </Text>
              <HeadingSummary>送料</HeadingSummary>
              <Text fontSize={{ base: 'md', md: '2xl' }} mb={4}>
                決済時に計算されます
              </Text>
              <HeadingSummary>総計</HeadingSummary>
              <Text fontSize={{ base: 'md', md: '2xl' }} mb={4}>
                {formatPrice(
                  checkout.subtotalPriceV2?.currencyCode || 'USD',
                  Number(checkout.subtotalPriceV2?.amount) || 0
                )}
              </Text>
            </Box>
            <Button
              bg="purple.500"
              size="lg"
              p={8}
              color="white"
              width="full"
              onClick={handleCheckout}
              disabled={loading}
              _focus={{ focus: 'none' }}
            >
              お支払いへ
            </Button>
          </Box>
        )}
      </Box>
    </Layout>
  );
}
