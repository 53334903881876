import * as React from 'react';
import { navigate } from 'gatsby';
import { AiOutlineDelete } from 'react-icons/ai';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage } from 'gatsby-plugin-image';
import { debounce } from 'lodash';

import { formatPrice } from '../utils/format-price';
import NumericInput from './NumericInput';
import {
  Box,
  Heading,
  Button,
  Td,
  Tr,
  useBreakpointValue,
  Flex,
  Stack,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { StoreContext } from '../context/StoreContext';

export default function LineItem({ item }: { item: any }) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext);

  const [quantity, setQuantity] = React.useState(item.quantity);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id as string, item.id);
  };

  const uli = debounce(
    value => updateLineItem(checkout.id as string, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = React.useCallback(value => uli(value), []);

  const handleQuantityChange = (value: any) => {
    if (value !== '' && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  );

  if (isMobile) {
    return (
      <Flex gap={4} justify="center" alignItems="center" mb={4}>
        <Box
          maxW="100px"
          onClick={() => navigate(`/product/${item.variant.product.handle}`)}
          _hover={{
            cursor: 'pointer',
          }}
        >
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </Box>
        <Stack>
          <Heading fontSize={{ base: 'md', md: 'xl' }}>{item.title}</Heading>
          <Heading fontSize="xs" textTransform="capitalize">
            {item.variant.title === 'Default Title' ? '' : item.variant.title}
          </Heading>
          <Text fontSize="xs">{price}</Text>
        </Stack>
        <Spacer />
        <Flex direction="column" gap={2}>
          <NumericInput
            handleOnChange={handleQuantityChange}
            value={quantity}
            aria-label="Quantity"
          />
          <Button
            bg="red"
            color="white"
            p={4}
            size="xs"
            leftIcon={<AiOutlineDelete />}
            onClick={handleRemove}
            variant="outline"
            disabled={loading}
          >
            削除
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Tr>
      <Td
        textAlign="center"
        onClick={() => navigate(`/product/${item.variant.product.handle}`)}
        _hover={{
          cursor: 'pointer',
        }}
      >
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        )}
      </Td>
      <Td>
        <Heading fontSize={{ base: 'md', md: '2xl' }}>{item.title}</Heading>
        <Box textTransform="capitalize">
          {item.variant.title === 'Default Title' ? '' : item.variant.title}
        </Box>
        <Box mt={4}>
          <Button
            bg="red"
            color="white"
            p={4}
            size="md"
            leftIcon={<AiOutlineDelete />}
            onClick={handleRemove}
            variant="outline"
            disabled={loading}
          >
            削除
          </Button>
        </Box>
      </Td>
      <Td fontSize={{ base: 'md', md: '2xl' }}>{price}</Td>
      <Td>
        <NumericInput
          handleOnChange={handleQuantityChange}
          value={quantity}
          aria-label="Quantity"
        />
      </Td>
      <Td fontSize={{ base: 'md', md: '2xl' }}>{subtotal}</Td>
    </Tr>
  );
}
