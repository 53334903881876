import * as React from 'react';

import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

type NumericInputProps = {
  value: number;
  handleOnChange: (valueAsString: string, valueAsNumber: number) => void;
  defaultValue?: number;
};
const NumericInput = ({
  value,
  handleOnChange,
  defaultValue = 0,
}: NumericInputProps) => {
  return (
    <NumberInput
      size="md"
      value={value}
      defaultValue={defaultValue}
      min={0}
      max={5}
      onChange={handleOnChange}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export default NumericInput;
